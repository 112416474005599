import React, { useEffect } from 'react'
import loadable from '@loadable/component'
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

import { kebabCase } from 'lodash'
import { TrimStr } from '../components/Trim'
import Img from "gatsby-image"

const ContactCall = loadable(() => import('../components/ContactCall'));
const ToC = loadable(() => import('../components/ToC'));
const PortfolioRoll2 = loadable(() => import('../components/PortfolioRoll2'));
const Packages = loadable(() => import('../components/Packages'));

export const OfferPageTemplate = ({
  id,
  subtitle,
  content,
  date,
  contentComponent,
  description,
  tags,
  title,
  slug,
  headings,
  helmet,
  location,
  headerimage,
  icon,
  footerimage,
  posts,
  offers,
  portfolios,
}) => {
  const PostContent = contentComponent || Content;

  // Filter portfolios based on matchig tags (max 3 for performance )
  let matchport = []
  if(portfolios !== undefined){
    //if for preview in cms (portfolios, posts & offers are not defined)
  portfolios.forEach(edge => {
    if((edge.node.frontmatter.tags.includes(title)) && (matchport.length < 3)){
      matchport.push(edge)
  }
  })}

  return (
    <>
    <section>

      {helmet}


      <div id="json"></div>

      {offers && offers.length ? (
        <div className="mobile-menu" style={{
          position:'absolute',
          zIndex:'100',
          right: '5px',
          top:'50px'
        }}>
          <ul style={{textAlign:'right'}}>
            {offers.map(off => (
              off.node.frontmatter.title === title ?
                <li key={off.node.frontmatter.title}>
                <Link className="btn" to={off.node.fields.slug} style={{padding:'10px',fontSize:'12px',backgroundColor:'#36b3d2'}}><span style={{color:'white'}}>{off.node.frontmatter.title}</span></Link>
                </li>
                :
                <li key={off.node.frontmatter.title}>
                <Link className="btn" to={off.node.fields.slug} style={{padding:'10px',fontSize:'12px'}} >{off.node.frontmatter.title}</Link>
                </li>
            ))}
          </ul>
        </div>
      ) : null}

      {headerimage ?
        headerimage.publicURL ?
         <div className="bigscreen-contain" style={{
         height:'400px',width:'100%',marginTop:'0px',
         backgroundImage: `url(${headerimage.publicURL})`,
         backgroundSize: 'cover',
         backgroundRepeat: 'no-repeat',
         backgroundPosition: 'bottom',
      }}>
        </div>
        :
        <div className="bigscreen-contain" style={{
        height:'400px',width:'100%',marginTop:'0px',
        backgroundImage: `url(${headerimage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom',
     }}>
       </div>
      : null
      }

      <div className="container content moboff" style={{marginTop:'50px'}}>
      <h1 className="title is-size-2 has-text-weight-bold is-bold-light ofm">
        {title}
      </h1>
      <p className='oftxt' style={{
        color: '#222222',
        lineHeight: '1.3',
        paddingLeft: '30px',
        borderRadius: '0px',
        marginTop: '0%',
        marginBottom:'50px',
        display: 'block',
        width: '70%',
        textAlign: 'left',borderLeft: '10px solid #36b3d2'}}
        >{description}</p>
      </div>

      <p className="ux" align="center" id="copywriting"><a className="btnoutline" href="#copywriting">⇩</a></p>

      <div style={{backgroundColor:'#f5f5f5', width:'100%'}}>
      <div className="ofmid columns content container" style={{textAlign:'center',display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'#f5f5f5',padding:'50px',margin:'auto'}}>
      <div className="column is-6" style={{backgroundColor:'rgba(255,255,255,0.0)',borderRadius:'10px',textAlign:'center'}}>
      {icon ?
        icon.childImageSharp ?
         <Img fluid={icon.childImageSharp.fluid}
         alt={title}
         title={title}
         style={{height:'200px',width:'200px',margin:'auto'}}
         imgStyle={{height:'200px',width:'200px'}}
        />
        :
        <img src={icon} width="200px" height="200px" alt={title} loading="lazy" />
      :null}

      </div>
      <br></br>
      <div className="column is-6">
      <h2 className="subtitle" align="left"><b>{subtitle}</b></h2>
      <p align="left">Co nas wyróżnia?</p>
      <div className="oflist" style={{textAlign:'left',marginTop:'20px'}}>
      {headings}
      </div>
      </div>
      </div>
      </div>

      {/*Oferta 2gi level*/}
      <div style={{marginTop:'30px',display:'flex',flexWrap:'wrap',paddingLeft:'10%',paddingRight:'10%',justifyContent:'center',alignItems:'center'}}>
      {posts &&
        posts
        .filter(post => post.node.frontmatter.category === title)
        .map(({ node: post }) => (
        <Link key={post.id} className="subofbox" to={post.fields.slug} >
        {post.frontmatter.featuredimage ?
          post.frontmatter.featuredimage.childImageSharp ?
            <div style={{position:'relative'}}>
             <Img className="box" fluid={post.frontmatter.featuredimage.childImageSharp.fluid}
             alt={post.frontmatter.category}
             title={post.frontmatter.title}
             style={{height:'200px',width:'200px'}}
             imgStyle={{border:'5px solid black',borderRadius:'10px'}}
            />
            <p className="btn" style={{backgroundColor:'rgba(0,0,0,0.8)',borderRadius:'0',position:'absolute',zIndex:'100',bottom:'0',left:'0',width:'190px'}}>{post.frontmatter.title}</p>
            </div>
          :
          <div className="box" style={{backgroundImage:`url(${post.frontmatter.featuredimage.publicURL})`}}>
              <p className="btn" style={{backgroundColor:'rgba(0,0,0,0.8)',borderRadius:'0'}}>{post.frontmatter.title}</p>
          </div>
         :
         <div className="box" style={{backgroundColor:'black'}}>
             <p className="btn" style={{backgroundColor:'rgba(0,0,0,0.8)',borderRadius:'0'}}>{post.frontmatter.title}</p>
         </div>
        }
        </Link>
      ))}
      </div>





      <div className="container content" style={{marginTop:'20px'}}>
        {/* Treść CMS */}
        <div style={{padding:'3.5%'}}>
          <div className="is-10 is-offset-1">
            <PostContent content={content} />
          </div>
            { title === 'Pakiety abonamentowe dla e-commerce' ? (
              <Packages />
            ):
             null
            }

            {tags && tags.length ? (
              <div style={{ marginTop: `4rem` }}>
                <p className='subtitle'>Tagi:</p>
                <ul className="taglist">
                  {tags.map(tag => (
                    <li key={tag + `- tag`}>
                      <Link className="btn" to={`/tagi/${kebabCase(tag)}/`} style={{padding:'10px',fontSize:'12px'}} >{tag}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}

        </div>
        <br />
      </div>


    </section>



    {/*Powiązane realizacje */}
    <div className="mobile-noimg" style={{padding:'0',position:'relative'}}>
      {footerimage ?
        footerimage.childImageSharp ?
        <div>
        <Img fluid={footerimage.childImageSharp.fluid}
           alt={title}
           title={title}
           style={{height:'100%',zIndex:'-1',position:'absolute',width:'100%'}}
           imgStyle={{height:'100%',width:'100%',
           zIndex:'-1',objectFit:'cover',
           filter: 'brightness(0.3)'}}
          />
        <div className="matchport" style={{
          zIndex:'10',padding:'2.5% 5%',minHeight:'400px'
        }}>
        { matchport.length ?
            <>
            <p className="title" style={{color:'white',marginBottom:'-38px'}}>
            Powiązane realizacje:
            </p>
            <PortfolioRoll2 posts={matchport} />
            </>
            : null
        }

        </div>
        </div>
        :
        <div style={{
        height:'100%',width:'100%',minHeight:'400px',
        backgroundImage: `url(${footerimage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
        backgroundColor: 'black',
        filter: 'brightness(0.3)'
      }}>
      { matchport.length ?
          <>
          <p className="title" style={{color:'white',marginBottom:'-38px'}}>
          Powiązane realizacje:
          </p>
          <PortfolioRoll2 posts={matchport} />
          </>
          : null
      }
        </div>
        : <div style={{
        height:'350px',width:'100%',
        backgroundColor: '#121212',
      }}>
      { matchport.length ?
          <>
          <p className="title" style={{color:'white',marginBottom:'-38px'}}>
          Powiązane realizacje:
          </p>
          <PortfolioRoll2 posts={matchport} />
          </>
          : null
      }
        </div>
      }

    </div>

    <ContactCall />

    </>
  );
};







OfferPageTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  subtitle: PropTypes.string,
  slug: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  headings: PropTypes.object,
  id: PropTypes.string
};

const OfferPage = ({ data }) => {

  const { markdownRemark: post } = data;
  const { suboffdata: posts } = data;
  const { offdata: offers } = data;
  const { pdata: portfolios } = data;

  useEffect(() => {
    if(post.frontmatter.english){
     document.getElementById('lang-switch').href = post.frontmatter.english
   }
   });

  const blogPostingSchemaOrgJSONLD = {
    '@context': 'http://schema.org',
    '@type': 'BlogPosting',
    url: 'https://e-multicontent.pl'+ post.fields.slug,
    name: post.frontmatter.title.substring(0, 60),
    alternateName: post.frontmatter.title.substring(0, 60) || '',
    headline: post.frontmatter.description.substring(0, 120),
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': 'https://e-multicontent.pl'+ post.fields.slug,
    },
    author: {
      '@type': 'Organization',
      name: 'Zespół e-multiconent.pl',
      url: 'https://e-multicontent.pl/o-nas/'
    },
    image: {
      '@type': 'ImageObject',
      url: 'https://e-multicontent.pl'+post.frontmatter.headerimage.publicURL,
    },
    datePublished: post.frontmatter.date,
    dateModified: post.frontmatter.date,
    publisher: {
      '@type': 'Person',
      name: post.frontmatter.author,
      logo: {
        '@type': 'ImageObject',
        url: 'https://e-multicontent.pl/icons/icon-512x512.png',
      },
    },
    description: post.frontmatter.description.substring(0, 145)+'...',
  }

  return (
    <Layout>
    <nav className="breadcrumbs-nav section">
        <Link className="inline-link" to="/">Home</Link>&nbsp;&gt;&nbsp;<Link className="inline-link" to="/oferta/">Oferta</Link>&nbsp;&gt;&nbsp;<span style={{fontWeight:'600',color:'#222'}}><b>{post.frontmatter.title}</b></span>
    </nav>
      <OfferPageTemplate
        content={post.html.replace(/href/g, "rel='noopener noreferrer' target='_blank' href")}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | e-multiconent.pl">
            <title>{`${post.frontmatter.title.substring(0, 60)}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description.substring(0, 145) +
                "..."}`}
            />
            <meta
              name="image"
              content={
                "https://e-multicontent.pl" +
                post.frontmatter.headerimage.publicURL
              }
            />

            <link rel="alternate" href={post.frontmatter.english} hrefLang="en-gb" />
            <link rel="alternate" href={post.frontmatter.english} hrefLang="en-us" />
            <link rel="canonical" href={"https://e-multicontent.pl" + post.fields.slug}  />

            {/* Schema.org tags */}
            <script type='application/ld+json'>
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Oferta",
                "item": "https://e-multicontent.pl/oferta/"
              },{
                "@type": "ListItem",
                "position": 2,
                "name": post.frontmatter.subtitle,
                "item": "https://e-multicontent.pl/oferta/"+TrimStr(post.fields.slug)+'/'
              }]
            })}
            </script>
            <script type='application/ld+json'>
              {JSON.stringify(blogPostingSchemaOrgJSONLD)}
            </script>


            {/* OpenGraph tags */}
            <meta
              property="og:title"
              content={post.frontmatter.title.substring(0, 60)}
            />
            <meta property="og:type" content="article" />
            <meta
              property="og:description"
              content={post.frontmatter.description.substring(0, 145) + "..."}
            />
            <meta
              property="og:image"
              content={
                "https://e-multicontent.pl" +
                post.frontmatter.headerimage.publicURL
              }
            />
            <meta
              property="og:url"
              content={"https://e-multicontent.pl" + post.fields.slug}
            />

            <meta name="twitter:card" content="summary_large_image" />
            <meta property="twitter:domain" content="e-multicontent.pl" />
            <meta
              property="twitter:url"
              content={"https://e-multicontent.pl" + post.fields.slug}
            />
            <meta
              name="twitter:title"
              content={post.frontmatter.title.substring(0, 60)}
            />
            <meta
              name="twitter:description"
              content={post.frontmatter.description.substring(0, 145) + "..."}
            />
            <meta
              name="twitter:image"
              content={
                "https://e-multicontent.pl" +
                post.frontmatter.headerimage.publicURL
              }
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        headerimage={post.frontmatter.headerimage}
        footerimage={post.frontmatter.footerimage}
        icon={post.frontmatter.icon}
        slug={post.fields.slug}
        id={post.id}
        subtitle={post.frontmatter.subtitle}
        headings={<ToC headings={post.headings}/>}
        posts={posts.edges}
        offers={offers.edges}
        portfolios={portfolios.edges}
      />

    </Layout>
  );
};

OfferPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  }),
  location: PropTypes.shape({}).isRequired
};

export default OfferPage;

export const pageQuery = graphql`
  query OfferPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      fields {
        slug
      }
      headings {
        value
        depth
      }
      html
      frontmatter {
        date(formatString: "DD. MM. YYYY")
        title
        subtitle
        description
        english
        tags
        headerimage {
          publicURL
        }
        footerimage {
          childImageSharp {
            fluid(maxWidth: 1500) {
              src
              srcSet
              srcWebp
              srcSetWebp
            }
          }
          publicURL
        }
        icon {
          childImageSharp {
            fluid(maxWidth: 250) {
              src
              srcSet
              srcWebp
              srcSetWebp
            }
          }
          publicURL
        }
      }
    }
    offdata: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "offer-page" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
    suboffdata: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "offer-post" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            category
            title
            templateKey
            featuredimage{
              childImageSharp {
                fluid(maxWidth: 1000) {
                  srcWebp
                  src
                  srcSet
                  srcSetWebp
                }
              }
              publicURL
            }
          }
        }
      }
    }
    pdata: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "portfolio-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          timeToRead
          frontmatter {
            title
            industry
            templateKey
            date(formatString: "DD. MM. YYYY")
            featuredpost
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  srcWebp
                  src
                  srcSet
                  srcSetWebp
                }
              }
              publicURL
            }
            backgroundimage {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  srcWebp
                  src
                  srcSet
                  srcSetWebp
                }
              }
              publicURL
            }
            tags
          }
        }
      }
    }
  }
`;
